import "whatwg-fetch";
import React, {Component} from "react";

import NormalPage from "./Page";
import FooterBar from "./FooterBar";
import { GoBackPrompt, InfoPopup, LanguagePrompt, QuestionPrompt } from "./Popups";
import { isFactors, isPrompt, isTextInput, logError, logOnDebug, logOnDebugLvl, translate } from "./helpers";
import { SKP_ID, SKP_INTVAL, DBG_LVL, API_HOST } from './constants';
import { deleteQuestionProgress, findNode, getStaticImgUrl, makeRespondentIsIn, mapBy } from "./utils";

export default class Survey extends Component {
    constructor(props) {
        super(props);
        this._initSurvey(props, true);
    }
    _initSurvey(props, isConstructor) {
        if (!props) props = {};

        this.timeoutTime = null;
        this.surveyData = props.initialSurveyData || (window.userData && window.userData.surveyData) || {};
        this.confirmedPrompts = [];
        this.hasStarted = false;
        this.initialPage = this._getPageAfter(-1);
        const { startPage, lastPrompt } = this._getStartPoint(props);
        this.confirmedPrompts = startPage
            ? props.pages.map((q, idx) => isPrompt(q) && idx < startPage ? idx : null).filter(Boolean)
            : [];

        let initialState = {
            lang: window.defaultLanguage || 'sv',
            isShowLanguagePrompt: false,
            isShowGoBackPrompt: false,
            isShowQuestionInfo: false,
            currentPage: props.startPage || props.startPage === 0 ? props.startPage : startPage || this.initialPage,
            questionPrompt: props.startPage || lastPrompt || null,
            animating: true,
            confirmations: {}
        };
        // eslint-disable-next-line
        if (isConstructor) this.state = initialState;
        else this.setState(initialState);
    }
    _getStartPoint(props) {
        const savedProgress = window.userData && window.userData.surveyData;
        if (!savedProgress || !props.pages) return {};

        let lastPrompt;
        const infoPages = [];
        const firstUnanswered = props.pages && props.pages.findIndex((page, idx) => {
            if (this._isSkipPage(page)) return false;
            const pageAnswers = savedProgress[page.id];
            if (page.type.tag === 'info-start') {
                infoPages.push(idx);
                return false;
            }
            if (pageAnswers) {
                infoPages.splice(0, infoPages.length);
                return isFactors(page)
                    && !page.options.every((f) => pageAnswers.some((o) => o.optionId === f.id));
            }
            if (isPrompt(page)) {
                lastPrompt = idx;
                return false;
            }
            return true;
        });
        if (firstUnanswered === -1) {
            this.hasFinishedProgress = true;
            const pagesCopy = [...props.pages];
            pagesCopy.reverse();
            const lastQ = props.pages.length - 1;
            const lastPage = lastQ - pagesCopy.findIndex((page) => !this._isSkipPage(page) && !isPrompt(page))
            const lastPrompt = lastQ - pagesCopy.findIndex((page) => !this._isSkipPage(page) && isPrompt(page));
            // Find last non skippable page;
            return {
                startPage: lastPage,
                lastPrompt: lastPrompt > lastPage ? lastPrompt : null,
            };
        }
        // ToDo: deal with skipped pages between lastPrompt and firstUnanswered
        if (lastPrompt && firstUnanswered === lastPrompt + 1) {
            return { startPage: firstUnanswered, lastPrompt };
        }

        if (infoPages.length > 0) {
            return { startPage: infoPages[0] };
        }
        return { startPage: firstUnanswered };
    }
    _resetSurvey() {
        this.form.reset();
        this.refs.footerBar.setState({surveyData: {}});
        this.pageNodes.forEach((pageNode) => pageNode.refs.question && pageNode.refs.question.reset());
        this._initSurvey();
    }

    render() {
        const { poorCondition, hidden } = this.props;
        if (this.props.pages) {
            let style = {
                display: this.state.surveyHidden ? 'none' : 'block'
            };
            if (this.props.backgroundImageUrl && !this.props.poorCondition.disableAnimations) {
                const overlayColor = this.props.theme.basedOn === "Light"
                    ? 'rgba(255, 255, 255, .5)'
                    : 'rgba(0, 0, 0, .5)';
                let overlay = 'linear-gradient(180deg, ' + overlayColor + ', ' + overlayColor + ')';
                style['backgroundImage'] = overlay + ', url(' + getStaticImgUrl(this.props.backgroundImageUrl) + ')';
                style['backgroundSize'] = 'cover';
                style['backgroundPosition'] = 'center';
            }
            if (this.props.backgroundColor) {
                style['backgroundColor'] = this.props.backgroundColor;
            }
            return (
                <div className={"h-window" + (poorCondition.disableAnimations ? '' : ' animated') + (hidden ? ' d-none' : '')}
                     style={style}
                     ref={(viewWin) => {this.viewWin = viewWin}}>
                    {['Light', 'Dark'].indexOf(this.props.theme.name) < 0 && this.props.theme.url
                        ? <link rel="stylesheet" type="text/css" href={`${API_HOST}${this.props.theme.url}`}/>
                        : <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/css/gr-' + this.props.theme.name.toLowerCase() + '.css'}/>}
                    <main>
                        <form ref={(formEL) => {this.form = formEL}}>
                            {this._getPages()}
                        </form>
                    </main>
                    <FooterBar toggleLanguagePrompt={this._toggleLanguagePrompt.bind(this)}
                               toggleQuestionInfo={this._toggleQuestionInfo.bind(this)}
                               showBackBtn={this._canShowBackButton()}
                               currentQuestion={this._getCurrentQuestion()}
                               questionPrompt={this.state.questionPrompt}
                               startingSurveyData={this.surveyData}
                               baseTheme={this.props.theme.basedOn}
                               showInfoBtn={this._canShowInfoBtn()}
                               advancePage={(isSkip) => this._advancePage(this.state.currentPage, isSkip)}
                               goBackPage={this._beforeGoBack.bind(this)}
                               languages={this.props.languages}
                               backText={this._getText('WA-Back') || {'sv': 'Bakåt', 'en': 'Back'}}
                               skipText={this._getText('WA-Skip') || {"en": "Skip", "sv": "Hoppa över"}}
                               proceedText={this._getText('WA-Next') || {"en": "Send", "sv": "Skicka"}}
                               lang={this.state.lang}
                               logo={this.props.logo}
                               ref="footerBar"/>
                    {this._getLanguagePrompt()}
                    {this._getInfoPopup()}
                    {this._getQuestionPrompts()}
                    {this._getGoBackPrompt()}
                </div>
            );
        }
        return <div className="loader">LOADING...</div>
    }

    componentDidMount() {
        if (this.props.startPage) {
            this.setState({'currentPage': parseInt(this.props.startPage, 10)});
            this._focusElement();
        }
    }

    _getPages() {
        this.pageNodes = [];
        let pages = this.props.pages.map((page, index) => page
            ? (
                <NormalPage
                    pageNumber={index}
                    page={page}
                    languages={this.props.languages}
                    setLanguage={this._setLanguage.bind(this)}
                    lang={this.state.lang}
                    currentPage={this.state.currentPage}
                    previousPage={this.state.previousPage}
                    stopAnimation={this._stopPageAnimation.bind(this)}
                    animating={this.state.animating}
                    isShowInfo={this.state.isShowQuestionInfo}
                    advancePage={this._advancePage.bind(this)}
                    setSurveyData={this._setSurveyData.bind(this)}
                    key={index}
                    setFooterVisibility={this._setFooterVisibility.bind(this)}
                    baseTheme={this.props.theme.basedOn}
                    poorCondition={this.props.poorCondition}
                    dkText={(window.translationsByQId && window.translationsByQId[page.id] && window.translationsByQId[page.id].dk)
                        || this._getText('WA-dk') || {en: "Don't know", sv: "Vet ej"}}
                    resetTimer={isTextInput(page) ? this._resetTimer.bind(this) : null}
                    proceedText={isTextInput(page) ? this._getText('proceedText') || {"en": "Send", "sv": "Skicka"} : null}
                    ref={(node) => this.pageNodes[index] = node}/>)
            : null
        );
        this.pageNodes = this.pageNodes.filter(Boolean);
        return pages;
    }

    _setFooterVisibility(visibility) {
        this.refs.footerBar.setState({visible: visibility});
    }
    _setLanguage(lang) {
        this.setState({lang: lang, isShowLanguagePrompt: false});
    }

    _toggleQuestionInfo() {
        this.setState({isShowQuestionInfo: !this.state.isShowQuestionInfo})
    }

    _toggleLanguagePrompt() {
        this.setState({isShowLanguagePrompt: !this.state.isShowLanguagePrompt})
    }

    _toggleGoBackPrompt() {
        this.setState({isShowGoBackPrompt: !this.state.isShowGoBackPrompt});
    }

    _advancePage(clickedPage, isSkip) {
        this.setState({isShowGoBackPrompt: false});
        if (this.props.fixedPage) return;
        if (this.state.currentPage !== clickedPage) return;
        const currentQuestion = this._getCurrentQuestion();
        this.hasStarted = true;
        if (!this.state.questionPrompt) {
            if (isTextInput(currentQuestion) && !isSkip) {
                if (!isSkip) {
                    this.pageNodes[this.state.currentPage].refs.question._sendValue();
                } else {
                    this.pageNodes[this.state.currentPage].refs.question.textInput.blur();
                }
            }

            let currentPageAnswerData = this.surveyData[this._getCurrentQuestion().id];

            if (isSkip) {
                if (isFactors(currentQuestion)) {
                    const ansDataMap = mapBy(currentPageAnswerData, (o) => o.optionId);
                    currentQuestion.options.forEach((o) => {
                        if (!ansDataMap[o.id]) {
                            this.props.submitAnswerPackage({ questionId: o.questionId, optionId: o.id, intValue: SKP_INTVAL });
                        }
                    });
                } else if (!currentPageAnswerData) {
                    this.props.submitAnswerPackage({
                        questionId: currentQuestion.id,
                        optionId: currentQuestion.maxAnswers !== 1 ? [SKP_ID] : SKP_ID,
                        intValue: SKP_INTVAL,
                    });
                }
            }
            if (currentPageAnswerData && !isFactors(currentQuestion)) {
                if (Array.isArray(currentPageAnswerData)) {
                    currentPageAnswerData.forEach(this.props.submitAnswerPackage)
                } else {
                    this.props.submitAnswerPackage(currentPageAnswerData);
                }
            }
        }
        if (this.state.questionPrompt) this.confirmedPrompts.push(this.state.questionPrompt);
        let nextPage = this.state.questionPrompt ? this._getPageAfter(this.state.questionPrompt): this._getNextPage();

        if (nextPage === false) {
            this._finishSurvey();
            return;
        }
        this.timeoutTime = this.props.pages[nextPage].timeout;
        this._resetTimer();
        this.props.notifyPageChange({idx: nextPage, page: this.props.pages[nextPage] });

        if (this.props.pages[nextPage] && isPrompt(this.props.pages[nextPage])) {
            this.setState({questionPrompt: nextPage});

            return;
        }

        //ToDo: calibrate scroll speed
        if (this.viewWin.scrollTop > 10) {
            let timerID = setInterval(function () {
                this.viewWin.scrollTop -=2;
                if (this.viewWin.scrollTop === 0) clearInterval(timerID);
            }.bind(this), 3);
        }

        this.setState({
            isShowLanguagePrompt: false,
            isShowQuestionInfo: false,
            questionPrompt: null,
            animating: true,
            previousPage: this.state.currentPage,
            currentPage: nextPage
        });
        this._focusElement();
    }

    _beforeGoBack() {
        if (this.props.fixedPage) return;

        const deleteAns = !this._currentPrompt() && this.props.pages[this.state.currentPage]['criteria'];
        const showGoBackPrompt = window.userData && window.userData.isSendout && localStorage.getItem(`${window.userData.uId}-go-back`) !== 'true';;
        if (deleteAns && showGoBackPrompt) {
            this.setState({ isShowGoBackPrompt: true });
            return;
        }
        this._goBackPage(deleteAns);
    }

    _goBackPage(deleteAns) {
        this.setState({isShowGoBackPrompt: false});
        if (this.props.fixedPage) return;

        if (deleteAns) {
            deleteQuestionProgress(this.props.pages[this.state.currentPage].id);
            this.pageNodes[this.state.currentPage].refs.question && this.pageNodes[this.state.currentPage].refs.question.reset();
            const resetData = {
                delete: true,
                questionId: this.props.pages[this.state.currentPage].id
            }
            this.props.submitAnswerPackage(resetData);
        }

        if (this.state.currentPage > this._getPageAfter(-1)) {
            let moveToPage = this._getPrevPage();
            this.timeoutTime = this.props.pages[moveToPage].timeout;
            this._resetTimer();

            this.setState({
                isShowLanguagePrompt: false,
                isShowQuestionInfo: false,
                previousPage: moveToPage === this.state.currentPage ? this.state.previousPage : this.state.currentPage,
                currentPage: moveToPage,
                questionPrompt: null,
                animating: true
            });
            this._focusElement();
        }
    }

    _focusElement() {
        const showingElem = document.getElementsByClassName('showing');
        if (showingElem && showingElem.length) {
            const legendElem = showingElem[0].getElementsByTagName('legend');
            if (legendElem && legendElem.length) {
                const headerElem = legendElem[0].getElementsByTagName('h1');
                if (headerElem && headerElem[0]) {
                    headerElem[0].focus();
                } else {
                    legendElem[0].focus();
                }
            }
        }
    }

    _stopPageAnimation(e) {
        if (e.target.className.indexOf('page') > -1) {
            this.setState({animating: false});
        }
    }

    _setSurveyData(fieldData) {
        let { questionId: qId } = fieldData.dataPackage,
            i;
        this.hasStarted = true;

        if (['unsetOption', 'setOption'].indexOf(fieldData.action) > -1) {
            if (!this.surveyData[qId]) this.surveyData[qId] = [];

            i = this.surveyData[qId].findIndex((el) => el.optionId === fieldData.dataPackage.optionId);
            this._resetTimer()
        }
        // ToDo: Set array of optionIds
        switch (fieldData.action) {
            case 'setOption':
                if (i === -1) this.surveyData[qId].push(fieldData.dataPackage);
                else this.surveyData[qId][i] = fieldData.dataPackage;
                break;
            case 'setQuestion':
                this.surveyData[qId] = fieldData.dataPackage;
                break;
            // case 'unsetOption':
            //     break;
            default:
                logError('Action not recognized')
        }

        this.refs.footerBar.setState({surveyData: this.surveyData});

        if (fieldData.type === 'factor' && !this.props.fixedPage) this.props.submitAnswerPackage(fieldData.dataPackage);
    }

    _getNextPage() {
        return this._getPageAfter(this.state.currentPage);
    }

    _getPageAfter(n) {
        for (let i = n + 1; i < this.props.pages.length; i++) {
            if (!this._isSkipPage(this.props.pages[i]) && this.confirmedPrompts.indexOf(i) < 0) {
                return i;
            }
        }
        return false;
    }

    _getPrevPage() {
        // If we are on a prompt, go back to the question itself
        if (this.state.questionPrompt) return this.state.currentPage;

        for (let i = this.state.currentPage - 1; i >= this.initialPage; i--) {
            if (!this._isSkipPage(this.props.pages[i]) && !isPrompt(this.props.pages[i])) {
                return i;
            }
        }
        return this.initialPage;
    }

    _isSkipPage(page) {
        if (!page['criteria']) return false;

        /* eslint-disable */
        let q = this._getQuestionOptionValue.bind(this);
        const d = (id, includeSubNodes) => {
            const divisionIds = window.userData && window.userData.divisionIds;
            logOnDebug('divisionIds', divisionIds);
            logOnDebug('includeSubNodes', includeSubNodes);

            if (!divisionIds) return false;
            if (!includeSubNodes || divisionIds.indexOf(id) > -1) return divisionIds.indexOf(id) > -1;

            logOnDebug('window.userData.orgChartIds', window.userData.orgChartIds);
            if (!window.userData.orgChartIds) return false;

            const respondentIsIn = makeRespondentIsIn(divisionIds);

            const branch = findNode(window.userData.orgChartIds, id);
            logOnDebug('id', id);
            logOnDebug('branch', branch);
            return branch && respondentIsIn([branch]);
        }
        const ip = (id) => {
            logOnDebug(this.props.locationId, id);
            return this.props.locationId && this.props.locationId === id;
        }
        const en = (id) => {
            logOnDebug(this.props.deviceId, id);
            return this.props.deviceId && this.props.deviceId === id;
        }
        if (!('dont_show' in window)) {
            Object.defineProperty(window, 'dont_show', { get: ()  => console.warn('Accessing "dont_show"') });
        }
        if (DBG_LVL) Object.assign(window, { d, q, ip, en });
        try {
            const condition = eval(page['criteria']);
            logOnDebugLvl(2)(page['criteria'], condition)
            return !condition;
        } catch (err) {
            logError(err);
            return true;
        }
        /* eslint-enable */
    }

    _getQuestionData(qId) {
        const qData = this.surveyData[qId];

        if (!qData || (!Array.isArray(qData) && Array.isArray(qData.optionId) && qData.optionId.length === 0)) {
            return;
        }

        return qData;
    }

    _getQuestionOptionValue(questionId, optionId) {
        //ToDo: deal with checkbox-bars, and text fields

        let question = this._getQuestionData(questionId);
        logOnDebugLvl(2)('_getQuestionOptionValue: question', question);
        if (!question) {
            return {selected: false, value: null};
        }

        // Case of factors
        if (Array.isArray(question)) {
            let option = question.find((answer) => answer.optionId === optionId);
            if (!option) {
                return {selected: false, value: null};
            }

            return {selected: true, value: option.intValue};
        }

        if (Array.isArray(question.optionId)) {
            let options = question.optionId;
            if (options.indexOf(optionId) === -1) {
                return {selected: false, value: null};
            }

            return {selected: true, value: null};
        }

        return {selected: question.optionId === optionId, value: question.intValue};
    }

    _getCurrentQuestion() {
        if (this.state.currentPage < 0) return false;

        // let page = this.props.pages[this.state.currentPage];
        // if (page.type === 'multi') {
        //     return findNot(page.subpages, this._isSkipPage.bind(this));
        // }
        return this.props.pages[this.state.currentPage];
    }

    _getInfoPopup() {
        const info = this._getCurrentQuestion() ? this._getCurrentQuestion().infoText : false;
        if (info) {
            return <InfoPopup toggleInfoPopup={this._toggleQuestionInfo.bind(this)}
                              isShow={this.state.isShowQuestionInfo} lang={this.state.lang}
                              info={info} caption={'Info'}/>
        }
    }

    _getGoBackPrompt() {
        return <GoBackPrompt 
                    show={this.state.isShowGoBackPrompt}
                    onAccept={() => this._goBackPage(true)}
                    webAppTexts={this.props.texts}
                    lang={this.state.lang}
                    toggleGoBackPrompt={this._toggleGoBackPrompt.bind(this)}
                />
    }

    _getQuestionPrompts() {
        return this.props.pages.map((prompt, idx) => isPrompt(prompt) && (
            <QuestionPrompt
                setCancel={this._finishSurvey.bind(this)}
                onTransitionEnd={() => this.setState({animating: false})}
                setConfirmation={() => this._advancePage(this.state.currentPage)}
                lang={this.state.lang} 
                isShow={idx === this.state.questionPrompt}
                yesText={prompt.options[0] ? translate(prompt.options[0].caption, this.state.lang) : null}
                noText={prompt.options[1] ? translate(prompt.options[1].caption, this.state.lang) : null}
                isDl={prompt.type.tag === 'info-end-download'}
                reportId={prompt.reportId}
                sendAgainBtnText={this._getText("WA-sendAgainBtn")}
                sendAgainTitleText={this._getText("WA-sendAgainTitle")}
                sendAgainLoadingText={this._getText("WA-sendAgainLoading")}
                resendAnswers={this._resendAnswers.bind(this)}
                caption={prompt.caption}
                text={prompt.text} key={"prompt" + idx}/>
            )).filter(p => !!p)

    }

    _getLanguagePrompt() {
        if (this.props.languages) {
            return <LanguagePrompt languages={this.props.languages}
                                   setLanguage={this._setLanguage.bind(this)}
                                   toggleLanguagePrompt={this._toggleLanguagePrompt.bind(this)}
                                   chooseLanguageText={{en: "Choose Language", sv: "Välj språk", ...this._getText('WA-test')}}
                                   isShow={this.state.isShowLanguagePrompt}
                                   lang={this.state.lang}/>
        }
    }

    _getText(key) {
        return (this.props.texts && this.props.texts[key]) || null;
    }

    _canShowInfoBtn() {
         const currentQuestion = this._getCurrentQuestion();
         return currentQuestion ? currentQuestion.infoText && translate(currentQuestion.infoText, this.state.lang) : false;
    }
    _canShowBackButton() {
        const currentPrompt = this._currentPrompt();

        return this.state.currentPage > this.initialPage
            && !(!this.hasFinishedProgress && currentPrompt && currentPrompt.type.tag.startsWith("info-end") && currentPrompt.timeout);
    }
    _currentPrompt() {
        return this.state.questionPrompt && this.props.pages[this.state.questionPrompt];
    }
    _stopTimer() {
        if (this.timer) clearTimeout(this.timer);
    }

    _resetTimer() {
        this._stopTimer();
        if (this.timeoutTime) {
            this.timer = setTimeout(this._finishSurvey.bind(this), this.timeoutTime * 1000);
        }
    }

    _resendAnswers() {
        this.props.sendUnsentAnswers();
    }

    _finishSurvey() {
        this._stopTimer();
        if (this._getCurrentQuestion() && this._getCurrentQuestion().type.family === 'comments') {
            this.pageNodes[this.state.currentPage].refs.question._sendValue();
            this.props.submitAnswerPackage(this.surveyData[this._getCurrentQuestion().id])
        }
        this.props.finishSurvey()
    }
}
